import React from 'react'
import { useTranslation } from 'react-i18next'


import ReactCountryFlag from "react-country-flag"


const LanguageSelector = () => {
  const { i18n } = useTranslation()

  const changeLanguage = (test) => {
    i18n.changeLanguage(test)
  }

  return (

<div>
      <ReactCountryFlag   value="es" name="language"   onClick={() => changeLanguage('es')}
    countryCode="AR"
    svg
    style={{
        width: '2em',
        height: '2em',
    }}
    title="AR"
  />

      <ReactCountryFlag className="flag" value="en" name="language" onClick={() => changeLanguage('en')}
    countryCode="US"
    svg
    style={{
        width: '2em',
        height: '2em',
    }}
    title="US"
/>

</div>
  )
}




export default LanguageSelector