import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Grid from "@material-ui/core/Grid";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";



const useStyles = makeStyles(styles);

export default function Flota(props) {


   const { t } = useTranslation();
    const classes = useStyles();

  return (
    <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={12}>
                <img
                  className="imagen"
                  src={props.datos.imagen}
                  alt="..."
                />
              </GridItem>

              <h4 className={[classes.cardTitle].join(" ")}>
                <Grid className="text-inside-grid" container>
                  <Grid item xs={6} sm={6} md={6}>
                    <strong>{t("matricula")}: </strong>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                  {props.datos.matricula}
                  </Grid>
                </Grid>

                <Grid className="text-inside-grid" container>
                  <Grid item xs={6} sm={6} md={6}>
                    <strong>{t("marca")}: </strong>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                  {props.datos.marca}
                  </Grid>
                </Grid>

                <Grid className="text-inside-grid" container>
                  <Grid item xs={6} sm={6} md={6}>
                    <strong>{t("modelo")}: </strong>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                  {props.datos.modelo}
                  </Grid>
                </Grid>
              </h4>
              <CardBody>
                <p className={[classes.description, "justificado"].join(" ")}>
                  {ReactHtmlParser(t(props.datos.texto))}
                </p>
              </CardBody>
            </Card>
          </GridItem>
  );
  }
