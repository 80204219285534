import config from '../config';

import LaravelService from './LaravelService'

const Laravel = new LaravelService();

  class EmailService {
      
    async Enviar(formData) {
        // Get a token from api server using the fetch api
        return Laravel.fetch(config.baseApi + '/email', {
            method: 'POST',
            body: JSON.stringify(formData)
        }).then(res => {
            return Promise.resolve(res);
        }).catch(error => {console.log(error);
            // var error = 
             new Error(error)
             throw error
         });
    }
}



export default EmailService