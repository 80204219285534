import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

import './Sections.css';

const AnyReactComponent = ({ text }) => <h4><div className="mapa">{text}</div></h4>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: -34.6635242,
      lng: -58.644254
    },
    zoom: 16
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCvj5a-_YOw4CG5iwCjKnlpfRaasJOfTtw"}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={-34.6635242}
            lng={-58.644254}
            text="Sky Flight"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;