import React from 'react';

import classes from './BackgroundVideo.module.css';

import Video1 from '../../assets/imagenes/video1.mp4'

const BackgroundVideo = () => {
    return (
        <div className={classes.Container} >
            <video autoPlay="autoplay" loop="loop" muted className={classes.Video} >
                <source src={Video1} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

export default BackgroundVideo