import React, { Suspense } from 'react'
import ReactDOM from "react-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";

import './idioma/i18n.js'


ReactDOM.render(<div>
      <Suspense fallback={null}>
<LandingPage></LandingPage>
</Suspense>
</div>,
  document.getElementById("root")
);
 