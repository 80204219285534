import config from '../config';

import LaravelService from './LaravelService'

const Laravel = new LaravelService();

  class Blogservice {

    async Noticias(pagina) {
        // Get a token from api server using the fetch api
        return Laravel.fetch(config.baseApi + '/noticias/'+pagina, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            // Do something for an error here
            console.log("Error Reading data " + err);
        });
    }
}
   

export default Blogservice