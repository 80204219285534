import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from 'react-i18next'

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import ReactHtmlParser from 'react-html-parser';

import Certificado1 from '../../../assets/imagenes/Habilitacion.jpg';
import Certificado2 from '../../../assets/imagenes/Habilitacion1.jpg';


import CertificadoPDF1 from '../../../assets/imagenes/Habilitacion.pdf';

import CertificadoPDF2 from '../../../assets/imagenes/Habilitacion1.pdf';




const useStyles = makeStyles(styles);

export default function Nosotros() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <h2 className={classes.title}>{t('nosotrosTitutlo')}</h2>
          <h5  className={[classes.description,"justificado"].join(' ')}>  
          { ReactHtmlParser(t('nosotrosTexto')) }
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
     <a href={CertificadoPDF1} target='_blank'> <img alt="Certificado" src={Certificado1}></img></a>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
          <a href={CertificadoPDF2} target='_blank'>  <img alt="Certificado" src={Certificado2}></img></a>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
