import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import { useTranslation } from 'react-i18next'
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/staff/Fabian.jpg";
import team2 from "assets/imagenes/LOGO_SF.png";

import team3 from "assets/img/staff/Gabriel.jpg";
import team4 from "assets/img/staff/Alejandra.jpg";
import team5 from "assets/img/staff/Rodrigo.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const { t, } = useTranslation();
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>{t('staff.nuestro')}</h2>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team1} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Fabian Lacencion
                <br />
                <small className={classes.smallTitle}>Gerente Responsable</small>
              </h4>    
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team3} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                  Ernesto Gabriel Nieto
                <br />
                <small className={classes.smallTitle}>Director de Estudios</small>
              </h4>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Pedro Pablo Villagra
                <br />
                <small className={classes.smallTitle}>Logística</small>
              </h4>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team2} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Monica Mabel Gimenez
                <br />
                <small className={classes.smallTitle}>Administración</small>
              </h4>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team4} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Alejandra Garcia
                <br />
                <small className={classes.smallTitle}>Administración</small>
              </h4>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={4}>
            <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={team5} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
              Rodrigo Dalto
                <br />
                <small className={classes.smallTitle}>Administración</small>
              </h4>
            </Card>
          </GridItem>


        </GridContainer>
      </div>
    </div>
  );
}
