import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import { useTranslation } from "react-i18next";



import LVCCL from "assets/imagenes/staff/LV-CCL.jpg";
import LVCHF from "assets/imagenes/staff/LV-CHF.jpg";
import LVCZT from "assets/imagenes/staff/LV-CZT.jpg";
import LVGKC from "assets/imagenes/staff/LV-GKC.jpg";
import LVGPQ from "assets/imagenes/staff/LV-GPQ.jpg";
import SIMULAR from "assets/imagenes/staff/SIMULAR.jpg";

import Flota from '../../Components/Flota'


const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
 
  const { t } = useTranslation();
  let FlotaLVCCL = {matricula: 'LV-CCL', marca: 'Cessna',modelo: "150",texto:"LV-CCL",imagen:LVCCL}
  let FlotaLVCHF = {matricula: 'LV-CHF', marca: 'Cessna',modelo: "150",texto:"LV-CCL",imagen:LVCHF}
  let FlotaLVCZT = {matricula: 'LV-CZT', marca: 'Cessna',modelo: "150",texto:"LV-CZT",imagen:LVCZT}
  let FlotaLVGKC = {matricula: 'LV-GKC', marca: 'Tecnam',modelo: "P-2002 JF",texto:"LV-GKC",imagen:LVGKC}
  let FlotaLVGPQ = {matricula: 'LV-GPQ', marca: 'Tecnam',modelo: "P-2002 JF",texto:"LV-GPQ",imagen:LVGPQ}
  let FlotaSIMULAR = {matricula: 'N/A', marca: 'SIMAR',modelo: "Simular II",texto:"SIMULAR",imagen:SIMULAR}


  return (
    <div className={classes.section}>
      <h2 className={classes.title}>{t("aeronaves")}</h2>

      <GridContainer>
          <Flota datos={FlotaLVCCL}></Flota>
          <Flota datos={FlotaLVCHF}></Flota>
          <Flota datos={FlotaLVCZT}></Flota>    
      </GridContainer>

      <GridContainer>
          <Flota datos={FlotaLVGKC}></Flota>
          <Flota datos={FlotaLVGPQ}></Flota>
          <Flota datos={FlotaSIMULAR}></Flota>    
      </GridContainer>


    </div>
  );
}
