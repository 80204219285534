import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper';

import '../Sections/Sections.css'

import ReactHtmlParser from "react-html-parser";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


export default function Talleres() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t } = useTranslation();


  return (
    <div className="cursosbody">

<div style={{color:"black"}} align="center"><h2>{t("menuTalleres")}</h2></div>

      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">

          <Tab label={t('talleresPresencial')} {...a11yProps(0)} />
          <Tab label={t('talleresOnline')} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Paper>

      <TabPanel value={value} index={0}>
        <div className='cursosTop'>
          <span className='cursosTexto'> 
          {ReactHtmlParser(t('talleresPresencialTexto'))} 
          </span>
        </div>
       
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className='cursosTop'>
          <span className='cursosTexto'> 
          {ReactHtmlParser(t('talleresOnlineTexto'))} 
          </span>
        </div>
      </TabPanel>
      
      </Paper>
    </div>
  );
}
