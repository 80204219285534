import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Gallery from 'react-grid-gallery';
import GaleriasService from '../../../servicios/GaleriasService';
import Chip from '@material-ui/core/Chip';

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useTranslation } from "react-i18next";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

const ChipStyles = makeStyles((theme) => ({
        root: {
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap'
        },
      }));


const galeriasService = new GaleriasService();




export default function Galeria() {
  const classes = useStyles();
  const { t } = useTranslation();

  const Chipsclasses = ChipStyles();

const [galerias, setGalerias] = useState([]);
const [imagenes, setimagenes] = useState([]);

async function getGalerias() {
        await galeriasService.Galerias().then((res) => {
                setGalerias(res);
        })
      }

      async function getImagenes(id) {
        await galeriasService.Imagenes(id).then((res) => {
                setimagenes(res);
        })
      }


      useEffect(() => {
        getGalerias();
      }, []);

      const handleClick = (id) => {
        getImagenes(id);
      };

      const itemsGalerias = galerias?.map((item) =>
      <Chip key={item.id} style={{margin: "5px"}} onClick={() => handleClick(item.id)} label={item.tag} variant="outlined" />
        );


  return (
    <div className={classes.section}>

      <div style={{color:"black"}} className={Chipsclasses.root}>
        <h3>{t("tusfotos")}  </h3>
   
        </div>
        <div style={{color:"black"}} className={Chipsclasses.root}>
        <h3> misfotos@sky-flight.com.ar </h3> </div> 



      <div style={{color:"black"}} className={Chipsclasses.root}>
        <h3><strong>{t("galeria")}</strong></h3>
        </div>

        <div className={Chipsclasses.root}>
        {itemsGalerias}
     </div>
      
           <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
       <Gallery images={imagenes}/>
       </GridItem>
       </GridContainer>

    </div>
  );
}
