import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import Nosotros from "./Sections/Nosotros.js";
import Staff from "./Sections/Staff.js";
import Aeronaves from "./Sections/Aeronaves.js";
import Contacto from "./Sections/Contacto.js";
import Cursos from "./Sections/Cursos.js";
import Galeria from "./Sections/Galeria.js";
import Noticias from "./Sections/Noticias.js";

import Teoricos from "./Sections/Teoricos.js";
import Talleres from "./Sections/Talleres.js";

import TextLoop from "react-text-loop";

import {Route, Switch,BrowserRouter } from "react-router-dom";

import './Landing.css';


import BackgroundVideo from './BackgroundVideo';

import { useTranslation } from 'react-i18next'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const years =  new Date().getFullYear() - 2011;

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const { t } = useTranslation();


  return (
    <BrowserRouter>
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={t('logo')}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      

      <Parallax filter image={require("assets/img/landing-bg.jpg")}>
      <BackgroundVideo />
    
        <div className={classes.container}>
     
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Sky-Flight</h1>
              <h3>
              <TextLoop>
                    <span>{t('frase_1')}</span>
                    <span>{years} {t('frase_2')}</span>
                    <span>{t('frase_3')}</span>
                </TextLoop>
              </h3>
              <br />
              <Button
                color="info"
                size="lg"
                href="http://skyflight.no-ip.net:7901/skyflight/index.asp"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('reservas')}
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div  className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
     
    <Switch>
      <Route path="/Nosotros" component={Nosotros} />
      <Route path="/Aeronaves" component={Aeronaves} />
      <Route path="/Staff" component={Staff} />
      <Route path="/Contacto" component={Contacto} />
      <Route path="/Cursos" component={Cursos} />
      <Route path="/Galeria" component={Galeria} />

      <Route path="/Cursosteoricos" component={Teoricos} />
      <Route path="/Charlasytalleres" component={Talleres} />


      <Route path="/"  component={Noticias} />
    </Switch>

        </div>
      </div>
      <Footer />
    </div>
    </BrowserRouter>
  );
}
