class LaravelService {

    constructor() {
        this.fetch = this.fetch.bind(this) // React binding stuff
    }

fetch(url, options) {
    // performs api calls sending the required authentication headers
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }

    return fetch(url, {
        headers,
        ...options
    })
    .then(this.handleErrors)
    .then(response => {
        return response.json()
    } )
    .catch(error => {console.log(error);
       // var error = 
        new Error(error)
        throw error
    });
}

handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}


}


export default LaravelService;