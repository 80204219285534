import config from '../config';

import LaravelService from './LaravelService'

const Laravel = new LaravelService();

  class GaleriasService {

    async Galerias() {
        // Get a token from api server using the fetch api
        return Laravel.fetch(config.baseApi + '/galerias', {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            // Do something for an error here
            console.log("Error Reading data " + err);
        });
    }

    async Imagenes(id) {
        // Get a token from api server using the fetch api
        return Laravel.fetch(config.baseApi + '/imagenes/'+id, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        }).catch(err => {
            // Do something for an error here
            console.log("Error Reading data " + err);
        });
    }


}
   

export default GaleriasService