import React, { useState } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';
import EmailService from '../../../servicios/EmailService';
import { Alert, AlertTitle } from '@material-ui/lab';

import SimpleMap from './maps'
import ReCAPTCHA from "react-google-recaptcha";
import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";
import { useTranslation } from 'react-i18next'
import './Sections.css';

import './Contacto.css';

const useStyles = makeStyles(styles);

export default function Contacto() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [datos, setDatos] = useState({
    nombre: "",
    email: "",
    mensaje: "",
    telefono: "",
    google: ""
  })

  const EmailServices = new EmailService();
  const [mensajeEnviado, setMensajeEnviado] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMail, setErrorEmail] = useState(false);
  const recaptchaRef = React.createRef();

  const handleInputChange = (event) => {
    setDatos({
      ...datos,
      [event.target.name]: event.target.value
    })

    if (datos.nombre === '' || datos.email === '') {
      setDisabled(true);
    } else { setDisabled(false); }
  }

  const handleGoogle = () => {
    setDatos({
      ...datos,
      ["google"]: recaptchaRef.current.getValue()
    })
  };

  async function enviarDatos() {
    setIsLoading(true);
    await EmailServices.Enviar(datos).then((res) => {
      setIsLoading(false);
      setMensajeEnviado(false);
    }).catch(error => {
      setErrorEmail(true);
      setIsLoading(false);
      setMensajeEnviado(false);
    })
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (datos.google === '') { alert("Verifica si no eres un robot") }
    else {
      enviarDatos();
    }
  }
  const tipoText = "outlined"



  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>{t('contacto.contactenos')}</h2>
          <h4 className={classes.description}>
          </h4>
        </GridItem>
      </GridContainer>


      <GridContainer>
        <GridItem>
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <GridContainer>

              <GridItem xs={12} sm={12} md={6}>
                <GridContainer className="texto">
                  <GridItem xs={12} sm={12} md={12}>
                    <h3 className={classes.title}> {t('contacto.texto')}</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <a href="https://goo.gl/maps/xmEwKmmtdZyjUMYs5" target="_blank">
                      <i className="fas fa-map-marker-alt"></i></a> Fray Justo Sta. Maria de Oro, Esquina Av. Figueroa Alcorta
								Castelar (Pdo. De Morón)
							    Provincia de Bs As. Argentina
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <i className="fas fa-phone"></i><a href="tel:003024630820"> {t('contacto.oficina')} +54 11 4629-7884</a>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <i className="fas fa-phone"></i><a href="tel:003024630820"> {t('contacto.aeropuerto')} +54 11 6934-4401</a>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <i className="fas fa-envelope"></i><a href="mailto:info@sky-flight.com.ar">info@sky-flight.com.ar</a>
                  </GridItem>

                </GridContainer>

              </GridItem>


              <GridItem xs={12} sm={12} md={6}>


                <div>

                  {isLoading ? <div align="center">
                    <Grid container className={classes.root} justify="center">
                      <Grid item xs={12} md={12}>
                        <CircularProgress color="secondary" />
                      </Grid>
                    </Grid>
                  </div>

                    : <div>
                      {mensajeEnviado ? <div>


                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            label={t('contacto.nombre')}
                            className="input" 
                            variant={tipoText}
                            name="nombre"
                            onChange={handleInputChange}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            label="Email"
                            className="input" 
                            variant={tipoText}
                            name="email"
                            onChange={handleInputChange}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                          <TextField
                            label={t('contacto.telefono')}
                            className="input" 
                            variant={tipoText}
                            name="telefono"
                            onChange={handleInputChange}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          label={t('contacto.mensaje')}
                          name="mensaje"
                          className="inputArea"
                          variant={tipoText}
                          multiline rows={2}
                          onChange={handleInputChange}
                        />
                    </GridItem>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                            <ReCAPTCHA
                               ref={recaptchaRef}
                              sitekey="6LeNCN8UAAAAAL8b7rzN2fRSs6ly3QjysPsmHk9X"
                              onChange={handleGoogle}
                            />
                          </GridItem>
                        </GridContainer>


                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
                            <br></br> <Button disabled={disabled} type="submit" fullWidth color="primary">{t('contacto.enviar')}</Button>
                          </GridItem>
                        </GridContainer>
                      </div>

                        :


                        <div><Grid container className={classes.root} justify="center">
                          <Grid item xs={12} md={6}>
                            <Grid item xs={12} md={12}>

                              {errorMail ?
                                <div>
                                  <Alert severity="error">
                                    <AlertTitle>Lo sentimos, hubo un error.</AlertTitle>
       Por favor intenta enviar el mensaje más tarde. — <strong>Muchas gracias</strong>
                                  </Alert>
                                </div>
                                :
                                <div>
                                  <Alert severity="success">
                                    <AlertTitle>Mensaje enviado!</AlertTitle>
       A la brevedad uno de nuestros representantes se comunicara con usted. — <strong>Muchas gracias!</strong>
                                  </Alert>
                                </div>
                              }

                            </Grid>
                          </Grid>
                        </Grid>
                        </div>
                      }
                    </div>}
      </div>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>



        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12}>

          </GridItem>
        </GridContainer>

        <SimpleMap></SimpleMap>

    </div>
  );
}
