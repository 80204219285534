import React, { useState, useEffect } from 'react';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Pagination from "react-pagination-library";
import "react-pagination-library/build/css/index.css"; //for css

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

import BlogsService from "../../../servicios/BlogsService";

import ReactHtmlParser, {
} from "react-html-parser";

import config from '../../../config';

const useStyles = makeStyles(styles);

const Entradas = new BlogsService();
/*
const [page, setPage] = React.useState(1);
const handleChange = (event, value) => {
  setPage(value);
};

hangeCurrentPage = numPage => {
  this.setState({ currentPage: numPage });
  //fetch a data
  //or update a query to get data
};
*/

export default function Noticias() {

  const [currentPage, setcurrentPage] = useState(1);
  const [noticias, setNoticias] = useState([]);

  function changeCurrentPage(numPage) {
    setcurrentPage(numPage)
    EntradasNoticias(numPage);
  }


  async function EntradasNoticias(pagina) {
    await Entradas.Noticias(pagina).then((res) => {
      setNoticias(res);
    })
  }

  useEffect(() => {
    EntradasNoticias(1);
  }, []);



  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem sm={6} md={8}>
          <h3 className={classes.title}>Novedades</h3>

          <div align="center">
            <Pagination
              currentPage={currentPage}
              totalPages={10}
              changeCurrentPage={changeCurrentPage}
              theme="bottom-border"
            />
          </div>

          <CardBody>
            <Card>
              <div align="center">
                <h3>{noticias?.titulo} </h3>
              </div>

              <div align="center">

                {ReactHtmlParser(noticias?.contenido)}

              </div>

              <div align="center">
                {noticias?.tipo === 0 || noticias?.tipo === 1 ?
                  <img style={{ width:'100%' }} alt="Noticias" src={config.baseImg + "/storage/blogs/" + noticias?.id + "/" + noticias?.img}></img>
                  : <div>
                  </div>}
              </div>

            </Card>
          </CardBody>



        </GridItem>
      </GridContainer>


    </div>
  );
}
