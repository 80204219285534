/*eslint-disable*/
import React from 'react'
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload,Home,Flight,BurstMode,ContactMail } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);



import LanguageSelector from '../../idioma/LanguageSelector.js'
import { useTranslation } from 'react-i18next'

import './Links.css'

export default function HeaderLinks(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
        <Button component={ Link }  onClick={()=> window.scrollTo(0, 500)}  to="/"
          color="transparent"
          className={classes.navLink}>
          <Home className={classes.icons} />
          {t('menuInicio')}
        </Button>  
     </ListItem>

       <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Sky-Flight"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Flight}
          dropdownList={[
            <Link onClick={()=> window.scrollTo(0, 500)}  to="/nosotros/" className={classes.dropdownLink}>
                {t('menuNosotros')}
                </Link>,
            <Link onClick={()=> window.scrollTo(0, 500)} to="/Aeronaves" className={classes.dropdownLink}>
              {t('menuAeronaves')}
                  </Link>,
                      <Link onClick={()=> window.scrollTo(0, 500)} to="/Staff" className={classes.dropdownLink}>
                           {t('menuStaff')}
                          </Link>,
            <Link onClick={()=> window.scrollTo(0, 500)} to="/Cursos" className={classes.dropdownLink}>
                  {t('menuServicios')}
                    </Link>,

<Link onClick={()=> window.scrollTo(0, 500)} to="/Cursosteoricos" className={classes.dropdownLink}>
{t('menuTeoricos')}
  </Link>,

<Link onClick={()=> window.scrollTo(0, 500)} to="/Charlasytalleres" className={classes.dropdownLink}>
{t('menuTalleres')}
  </Link>,
          ]}
        />
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button component={ Link } onClick={()=> window.scrollTo(0, 500)} to="/Galeria"
          color="transparent"
          className={classes.navLink}>
          <BurstMode className={classes.icons} />
            {t('menuImagenes')}
        </Button>  
       </ListItem>
  
      <ListItem className={classes.listItem}>
        <Button component={ Link } onClick={()=> window.scrollTo(0, 500)} to="/Contacto"
          color="transparent"
          className={classes.navLink}>
          <ContactMail className={classes.icons} />
           {t('menuContacto')}
        </Button>  
       </ListItem>


      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title={t('redes')+" facebook"}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/skyflightschool"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title={t('redes')+" instagram"}
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/escuelaskyflight/?hl=es-la"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </ListItem>

      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}>
          <LanguageSelector className='flag'/>
        </Button>  
     </ListItem>

    </List>
  );
}
